import React, {useCallback} from 'react'
import { useSelector } from "react-redux"
import _ from 'lodash'
import Settings from "utils/Settings"
import FileUploadIcon from "@material-ui/icons/CloudUpload";
import DropzoneS3Uploader from "components/Upload/DropzoneS3Uploader"

import { Typography } from "@material-ui/core"
import { useStyles } from "./styles"
import { useTranslation } from 'react-i18next';
import { useSnackbar } from "notistack";

const ItemDropzone = ({ className, children, context_id }) => {
  const { userData: { upload_config } } = useSelector((state) => state.appReducer)
  const classes = useStyles()
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onError = useCallback((errors) => {
    errors.forEach(error => {
      let message = error.message;
      if(typeof message !== 'string') {
        message = t("manager:Erro inesperado. Contate o suporte")
      }
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      })
    })
  }, [enqueueSnackbar, t])

  const dragDisplay = (isDragActive) => {
    if(isDragActive) {
      return <div className={classes.dragDisplay}>
                <FileUploadIcon className={classes.cloudUploadIcon} />
                <Typography variant="h5" gutterBottom>
                  {t("DAM:Solte os arquivos aqui para adicionar ao item")}
                </Typography>
            </div>
    }
    return <></>
  }
  return (
    <DropzoneS3Uploader
      config={{
          acceptedExtensions: Settings.ACCEPTED_EXTENSIONS,
          entity: 'item',
          bucket_name:  _.get(upload_config, 'items_upload_bucket', Settings.BUCKET_NAME_ITEM),
          tagging: '',
          maxFilesUploads: 500,
          context_id: context_id,
          max_size_file: '5368709120' //bytes
      }}
      withPlaceholder={false}
      className={className}
      dragDisplay={dragDisplay}
      context_id={context_id}
      onError={onError}
    >
      {children}
    </DropzoneS3Uploader>
  )
}

export default ItemDropzone
